import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import ContactForm from '../../../components/forms/contact-form'
import {ContactFormWrapper, LeftBox} from './contact-form-area.style'
import ContactInfoWrapper from '../contact-info-area'

const ContactFormArea = ({headingStyle, textStyle}) => {
    return (
        <ContactFormWrapper>
            <Container>
                <Row alignitems="center">
                    <Col lg={5}>
                        <LeftBox>
                            <Heading {...headingStyle}>Get in <span>Touch </span><br/> further information.</Heading>
                            <Text {...textStyle}>We just need a couple of hours! No more than 2 working days since receiving mail</Text>
                        </LeftBox>
                    </Col>
                    <Col lg={7}>
                        {/* <ContactForm/> */}
                        <ContactInfoWrapper/>
                        
                    </Col>
                </Row>
            </Container>
        </ContactFormWrapper>
    )
}

ContactFormArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

ContactFormArea.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        color: '#696969'
    }   
}

export default ContactFormArea;