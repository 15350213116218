import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import Anchor from '../../../components/ui/anchor'
import { ContactInfoWrapper, InfoBoxWrap, InfoBox,InfoBoxWrap1 } from './contact-info-area.style'
import { MdMail, MdPhone, MdHome } from 'react-icons/md'

const ContactInfoArea = ({ headingStyle, textStyle, phoneStyle,iconStyle }) => {
    const contactInfo = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    contact {
                        addressInfos {
                            id
                            state
                            address
                            email
                            phone
                        }
                    }
                }
            }
        }      
    `);
    const { addressInfos } = contactInfo.site.siteMetadata.contact;
    return (
        <ContactInfoWrapper>
            <Container>
                <Row>
                    <Col lg={6} >
                        <InfoBoxWrap>
                            <img className="iconStyle" src="../../../../email-icon.svg" alt="email"></img>
                            {/* <MdMail className="iconStyle"/> */}
                            <Heading {...headingStyle}>Say Hello</Heading>
                            <InfoBox>
                                <Anchor path={`mailto:info@zirafftechnologies.com`}>info@zirafftechnologies.com</Anchor>
                            </InfoBox>
                        </InfoBoxWrap>
                    </Col>
                    {/* <Col lg={4} >
                        <InfoBoxWrap>
                        <img className="iconStyle" src="../../../../phone-icon.svg" alt="email"></img>
                            <MdPhone className="iconStyle"/>
                            <Heading {...headingStyle}>Call for Advice Now</Heading>
                            <InfoBox>
                                <Text {...phoneStyle}>+91-40-65242211</Text>
                            </InfoBox>
                        </InfoBoxWrap>
                    </Col> */}
                    <Col lg={6} >
                        <InfoBoxWrap1>
                        <img className="iconStyle" src="../../../../location-icon.svg" alt="email"></img>
                            {/* <MdHome className="iconStyle"/> */}
                            <Heading {...headingStyle}>Reach Us</Heading>
                            <InfoBox>
                                <Text>7-1-397/91 2nd floor, Anuna building,MIGH Colony,SR Nagar,Hyderabad-500038</Text>
                            </InfoBox>
                        </InfoBoxWrap1>
                    </Col>
                </Row>
                
            </Container>
        </ContactInfoWrapper>
    )
}

ContactInfoArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    phoneStyle: PropTypes.object,
}

ContactInfoArea.defaultProps = {
    headingStyle: {
        as: "h5",
        mb: "20px"
    },
    textStyle: {
        mb: "7px"
    },
    phoneStyle: {
        fontweight: 800,
        color: 'headingColor'
    },
    iconStyle :{
        color : 'themeColor',
        fontsize:'40px'
    }
}

export default ContactInfoArea;