import styled from 'styled-components'
import { device } from '../../../theme'

export const ContactInfoWrapper = styled.div`
    padding-bottom: 60px;
    @media ${device.medium}{
        padding-bottom: 40px;
    }
    @media ${device.small}{
        padding-bottom: 30px;
    }
`;

export const InfoBoxWrap = styled.div`
    margin-top: 30px;
    position : relative;
    .iconStyle{
        font-size:40px;
        color: #f36d21;
        margin-bottom: 20px;
    }
    // &:after{
    //     content: "";
    //     border:2px solid #f36d21;
    //     position: absolute;
    //     top: 15px;
    //     width:2px;
    //     right: 50px;
    //     height: 100px;
    // }
   
`;
export const InfoBoxWrap1 = styled.div`
    margin-top: 30px;
    position : relative;
    .iconStyle{
        font-size:40px;
        color: #f36d21;
        margin-bottom: 20px;
    }
   
   
`;

export const InfoBox = styled.div``;